import React from 'react';
import { Center, Container } from '@fiverr-private/layout_components';
import IdentificationForm from '@fiverr-private/user_session/entries/IdentificationForm';
import { SigningMode } from '../../../types';
import * as classes from './Identification.ve.css';
import '../shared/styles/Global.ve.css';

interface IdentificationProps {
  mode: SigningMode;
  autoShowSignUpEnabled: boolean;
  googleClientId: string;
}

export const Identification = ({ mode, autoShowSignUpEnabled, googleClientId }: IdentificationProps) => (
  <Center paddingY={{ default: '0', md: '10' }}>
    <Container
      className={classes.formBoxShadow}
      height={{ default: '645px', sm: 'min-content', md: '645px' }}
      width={{ default: 'max-content', md: '875px' }}
      maxWidth="100%"
      backgroundColor="white"
      borderRadius="xl"
      overflow="hidden"
    >
      <IdentificationForm
        isSignInIntention={mode === 'sign_in'}
        autoShowSignUpEnabled={autoShowSignUpEnabled}
        socialPlatforms={{ google: { clientId: googleClientId } }}
      />
    </Container>
  </Center>
);
